import {Component, effect, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {CardModule} from '@progress/kendo-angular-layout';
import {IPost, IPostComment, IPostCommentRequestBody, IPostCommentResponseBody} from '../../../interfaces/i-post';
import {PostCommentComponent} from './post-comment/post-comment.component';
import {DateFromPipe} from '../../../pipes/date-from.pipe';
import {MatButtonModule} from '@angular/material/button';
import {Router} from '@angular/router';
import {NavItemLabels, ResultType, RoutePath} from '../../../enums/api.enum';
import {BreakpointService} from '../../../services/breakpoint.service';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MyPostsService} from '../services/my-posts.service';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {SpinnerService} from '../../../shared/spinner/spinner.service';
import {AlertService} from '../../../shared/alert/alert.service';
import {FormFieldModule, InputsModule} from '@progress/kendo-angular-inputs';
import {LabelModule} from '@progress/kendo-angular-label';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {SVGIcon, paperPlaneIcon} from '@progress/kendo-svg-icons';
import {MatChipsModule} from '@angular/material/chips';
import {ProfileService} from '../../user-profile/services/profile.service';
import {Subscription} from 'rxjs';
import {AlertEnum} from '../../../shared/alert/alert.enum';
import {SENSITIVITY_LABELS} from "../../../constants/sensitivity.constants";
import {
  CommentActionsComponent
} from "../../unified-search/search-result-list/result-card/comment-actions/comment-actions.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AddCommentComponent} from "./add-comment/add-comment.component";
import { FILE_DOWNLOAD_BASE_URL, FILE_DOWNLOAD_KEY } from '../../../constants/api.constant';
import { UiLayoutService } from '../../../ui-layout/services/ui-layout.service';

@Component({
  selector: 'app-post-card',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    PostCommentComponent,
    DateFromPipe,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,
    ButtonModule,
    CommentActionsComponent,
    LabelModule,
    InputsModule,
    AddCommentComponent
  ],
  templateUrl: './post-card.component.html',
  styleUrl: './post-card.component.scss'
})
export class PostCardComponent implements OnInit, OnDestroy {
  @Input() myPost!: Partial<IPost>;
  // isViewCard lets the UI know if the user is viewing the post itself and not within a post list.
  @Input() isViewCard = false;

  showComments = false;
  backUrl = '';
  isSmallViewPort = false;
  showAddComment = false;
  toolTipLabelAddComment = 'Add a comment';
  ariaLabelAddComment = 'Add comment on the post.';
  // An array of attached files that the user has uploaded
  attachedFiles: {name: string, path: string}[] = [];
  keywords: string[] = [];
  isUserPost = false;
  isEnabled!: boolean;
  sensitivityLabel = '';
  resultType = ResultType;
  

  profileService = inject(ProfileService);
  #router = inject(Router);
  #breakpointService = inject(BreakpointService);
  #myPostsService = inject(MyPostsService);
  #spinnerService = inject(SpinnerService);
  #alertService = inject(AlertService);
  #uiLayoutService = inject(UiLayoutService);

  readonly #subscriptions: Subscription[] = [];

  constructor() {
    this.backUrl = this.#router.getCurrentNavigation()?.previousNavigation?.finalUrl!.toString()!;
    this.isSmallViewPort = this.#breakpointService.isSmallViewport();

    const subscription = this.#myPostsService.getNewCommentEvent().subscribe({
      next: (resp: IPostCommentResponseBody) => {
        if (resp.postId === this.myPost.postId) {
          this.appendNewComment();
        }
      }
    });

    this.#subscriptions.push(subscription);

    // effect(() => {
    //   if (this.#myPostsService.newComment()) {
    //     if (this.myPost.postId === this.#myPostsService.newComment()?.postId) {
    //       this.appendNewComment();
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    this.#uiLayoutService.updateNavItems(NavItemLabels.MyPosts);
    this.isUserPost = (this.profileService.userProfile().id === this.myPost.userId);
    this.isEnabled = this.myPost.enable!;
    this.sensitivityLabel = this.#getSensitivityLabel();
    if (this.myPost.files) {
      let files = this.myPost.files.split(',');
      for (const file of files) {
        this.attachedFiles.push({name: file, path: `${FILE_DOWNLOAD_BASE_URL}/${file}?${FILE_DOWNLOAD_KEY}`});
      }
    }

    if (this.myPost.keywords) {
      if (Array.isArray(this.myPost.keywords)) {
        this.keywords = this.myPost.keywords;
      } else {
        this.keywords = JSON.parse(this.myPost.keywords);
      }
    }
  }

  ngOnDestroy() {
    this.#subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onViewComment() {
    if (!this.isViewCard) {
      const url = `/${RoutePath.MyPosts}`;
      this.#router.navigate([url, this.myPost.postId]);
    }
  }

  onBack() {
    const url = `/${RoutePath.MyPosts}`;
    this.#router.navigate([url]);
  }

  showAddCommentForm(): void {
    this.showAddComment = !this.showAddComment;
    if (this.showAddComment) {
      this.ariaLabelAddComment = 'Cancel comment.';
      this.toolTipLabelAddComment = 'Cancel comment';
    } else {
      this.ariaLabelAddComment = 'Add comment on the post.';
      this.toolTipLabelAddComment = 'Add a comment';
    }
  }

  onEnableDisablePost() {
    // Api call here to enable/disable post
  }

  appendNewComment() {
    // There are no comments. API returns null; change to empty list.
    // if (!this.myPost.comments) {
    //   this.myPost.comments = [];
    // }

    this.#spinnerService.show();

    const subscription = this.#myPostsService.getPost(this.myPost.postId!).subscribe({
      next: resp => {
        if (Object.hasOwn(resp, 'result')) {
          this.#alertService.showAlert({
            message: 'Error occurred while getting the post.',
            type: AlertEnum.error,
          });
        } else {
          this.myPost = {
            ...resp,
            metadataStoragePath: resp.metadata_storage_path
          };
        }

        // Since the user has added a comment; show the comments
        this.showComments = true;
        this.#spinnerService.hide();
      },
      error: err => {
        this.#alertService.showAlert({
          message: err.message,
          type: AlertEnum.error,
        });

        this.#spinnerService.hide();
      }
    });

    this.#subscriptions.push(subscription);
  }

  /**
   * Deletes the selected file from the file server.
   * @param fileName 
   */
  deleteAttachedFile(fileName: string) {
    // TODO: Make call to API to delete file from the server
    // Copy array
    const files = this.attachedFiles.slice();
    this.attachedFiles = files.filter(file => file.name !== fileName);
  }

  /**
   * Deletes the screen share video from the file server.
   */
  deleteScreenShareVideo() {
    this.myPost.metadataStoragePath = undefined;
  }

  #getSensitivityLabel(): string {
    return SENSITIVITY_LABELS.find(item => item.id === this.myPost.sensitivity)?.label!;
  }

}
