import { INavMenuItem } from '../interfaces/i-nav-menu-item';
import {
  textTruncateIcon,
  fileTxtIcon,
  searchIcon,
  groupHeaderSectionIcon
} from '@progress/kendo-svg-icons';
import { NavItemLabels, RoutePath } from '../enums/api.enum';

export const NAV_ITEMS: INavMenuItem[] = [
  {
    text: NavItemLabels.Feed,
    svgIcon: groupHeaderSectionIcon,
    icon: 'feed',
    selected: true,
    path: RoutePath.Feed
  },
  {
    text: NavItemLabels.Search,
    svgIcon: searchIcon,
    icon: 'search',
    selected: false,
    path: RoutePath.Search
  },
  {
    text: NavItemLabels.MyStuff,
    svgIcon: textTruncateIcon,
    icon: 'thumbs_up_down',
    selected: false,
    path: RoutePath.MyStuff
  },
  {
    text: NavItemLabels.AddPost,
    svgIcon: fileTxtIcon,
    icon: 'post_add',
    selected: false,
    path: RoutePath.AddPost
  },
  {
    text: NavItemLabels.MyPosts,
    svgIcon: fileTxtIcon,
    icon: 'post',
    selected: false,
    path: RoutePath.MyPosts
  },
  // {
  //   text: NavItemLabels.AIAssist,
  //   svgIcon: fileTxtIcon,
  //   icon: 'cognition',  // psychology, mystery
  //   selected: false,
  //   path: RoutePath.AIAssist
  // },
  // {
  //   text: NavItemLabels.BDAssist,
  //   svgIcon: fileTxtIcon,
  //   icon: 'psychology_alt',  // psychology, format_shapes, manage_search, find_in_page, quick_reference_all, category_search, search_insights
  //   selected: false,
  //   path: RoutePath.BDAssist
  // }
];
