import {ISensitivityLabel} from "../interfaces/i-sensitivity-label";

export const SENSITIVITY_LABELS: ISensitivityLabel[] = [
  {
    id: 1,
    label: 'Personal'
  },
  {
    id: 2,
    label: 'Public'
  },
  {
    id: 3,
    label: 'CGI - Internal'
  },
  {
    id: 4,
    label: `CGI - Internal\\Third Party`
  },
  {
    id: 5,
    label: `CGI - Internal\\CGI Parent & Affiliate`
  },
  {
    id: 6,
    label: 'CGI - Confidential'
  },
  {
    id: 7,
    label: `CGI - Confidential\\Third Party`
  },
  {
    id: 8,
    label: `CGI - Confidential\\CGI Parent & Affiliate`
  },
  {
    id: 9,
    label: 'CGI - Highly Confidential'
  },
  {
    id: 10,
    label: `CGI - Highly Confidential\\Third Party`
  },
  {
    id: 11,
    label: `CGI - Highly Confidential\\CGI Parent & Affiliate`
  },
  {
    id: 12,
    label: 'CUI'
  },
  {
    id: 13,
    label: `CUI\\NOFORN`
  },
  {
    id: 14,
    label: `CUI\\FEDCON`
  },
  {
    id: 15,
    label: `CUI\\DL ONLY`
  }
];
