import { Injectable, signal } from '@angular/core';
import { NAV_ITEMS } from '../../constants/nav-items.constant';
import { INavMenuItem } from '../../interfaces/i-nav-menu-item';

@Injectable({
  providedIn: 'root'
})
export class UiLayoutService {

  navItems = signal<INavMenuItem[]>(NAV_ITEMS);


  /**
   * Update the nav items.  The given nav item will be the new selected nav item.
   * @param selectedNavItem The new selected nav item.
   */
  updateNavItems(selectedNavItem: string) {
    const menuItems = this.navItems().slice();

    const items = menuItems.map(item => {
      return {
        ...item,
        selected: item.text === selectedNavItem
      }
    });

    this.navItems.set(items);
  }
}
