@defer (when myPost) {
<kendo-card class="result-card" width="auto" [ngClass]="{'result-card-sm': isViewCard}">
  <kendo-card-body class="result-card-body">
    <div class="result-card-header">
      <h4 kendoCardTitle class="title" [ngClass]="{'title-sm': isSmallViewPort}">
        @if (isViewCard) {
        <!-- Show the back button if viewing the post only -->
        <div class="card-title">
          <div class="btn-back">
            <button mat-icon-button type="button" (click)="onBack()" aria-label="Go back">
              <mat-icon>keyboard_backspace</mat-icon>
            </button>
          </div>
          <div>{{ myPost.title }}</div>
        </div>
        } @else {
        <button mat-button type="button" (click)="onViewComment()">{{ myPost.title }}</button>
        }
      </h4>

      <div class="title-right">
        <div class="sensitivity-label k-card-subtitle">
          <mat-icon class="material-symbols-outlined">shield</mat-icon>
          <span><strong>Sensitivity: {{ sensitivityLabel }}</strong></span>
        </div>
        @if (!isSmallViewPort && !isViewCard) {
        <div class="cred-wrapper">
          <div class="avatar">{{ myPost.firstName?.charAt(0) }}{{ myPost.lastName?.charAt(0) }}</div>
          <div class="user">
            <div class="date">{{ myPost.dateUpdated! | dateFrom }}</div>
            <div class="name">{{ myPost.firstName }} {{ myPost.lastName }}</div>
          </div>
        </div>
        }
      </div>
    </div>

    @if (isSmallViewPort && isViewCard) {
    <div class="date">{{ myPost.dateUpdated! | dateFrom }}</div>
    <!--        <div class="cred-wrapper">-->
    <!--                    <div class="badge">{{ myPost.firstName?.charAt(0) }}{{ myPost.lastName?.charAt(0) }}</div>-->
    <!--          <div class="user">-->
    <!--            <div class="date">{{ myPost.dateUpdated! | dateFrom }}</div>-->
    <!--                        <div class="name">{{ myPost.firstName }} {{ myPost.lastName }}</div>-->
    <!--          </div>-->
    <!--        </div>-->
    }



    <div class="view-comment">
      <h5 kendoCardSubtitle class="subtitle">
        {{ myPost.post }}
      </h5>

      @if (attachedFiles.length > 0) {
      <div class="attached-files">
        <div>Attached files:</div>
        <ul class="file-list">
          @for(file of attachedFiles; track file) {
          <li>
            <a [href]="file.path" target="_blank">{{file.name}}</a>

            @if (profileService.userProfile().id === myPost.userId) {
            <button mat-icon-button #tooltip="matTooltip" matTooltipPosition="above"
              [matTooltip]="'Delete ' + file.name" [attr.aria-label]="'Delete ' + file.name" type="button"
              (click)="deleteAttachedFile(file.name)">
              <mat-icon class="material-symbols-outlined">delete</mat-icon>
            </button>
            }
          </li>
          }
        </ul>
      </div>
      }

      <!-- SHOW SHARE SCREEN VIDEO -->
      @if (myPost.metadataStoragePath) {
      <div class="video-container" [ngClass]="{'sm-vd': isSmallViewPort}">
        <video controls>
          <source [src]="myPost.metadataStoragePath" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="delete-vid-btn-wrapper">
          @if (isSmallViewPort) {
          <span aria-hidden="true">Delete video</span>
          }

          <!-- Only show delete button if the Post is the user's. -->
          @if (profileService.userProfile().id === myPost.userId) {
          <button mat-icon-button type="button" #tooltip="matTooltip" matTooltipPosition="above"
            matTooltip="Delete video" aria-label="Delete video" (click)="deleteScreenShareVideo()">
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
          </button>
          }
        </div>
      </div>
      }
      <!-- END SHOW SHARE SCREEN VIDEO -->

    </div>
    
    <!-- enable/disable post -->
    <!-- Only show if the post is the user's. -->
    @if (isUserPost) {
      <div class="enable-wrapper">
        <kendo-label text="Enable: &nbsp;">
          <kendo-switch [(ngModel)]="isEnabled" (click)="onEnableDisablePost()" size="large" trackRounded="full"
          thumbRounded="full"></kendo-switch>
        </kendo-label>
      </div>
    }
    <!-- END enable/disable post -->
    
    <!-- SHOW KEYWORDS -->
    @if (keywords && keywords.length > 0) {
    <mat-chip-set class="chip-list" aria-label="Searched items in people, keyphrases and organizations">
      @for (item of keywords; track item) {
      <mat-chip>{{ item }}</mat-chip>
      }
    </mat-chip-set>
    }
    <!-- SHOW KEYWORDS -->
     
    <!-- Footer -->
    <hr kendoCardSeparator />
    <div class="actions" [ngClass]="{'actions-sm': isSmallViewPort}">
      <div class="stats" [ngClass]="{'stats-sm': isSmallViewPort}">
        <span>3 likes</span>
        <span>1 share</span>
        @if (myPost.comments && myPost.comments.length > 0) {
        <div class="comment-stats">
          <span>{{ myPost.comments.length }} comments</span>
          <button mat-button type="button" (click)="showComments = !showComments">
            @if (showComments) {
            Hide
            } @else {
            Show
            }
            @if (myPost.comments.length > 1) {
            comments
            } @else {
            comment
            }
          </button>
        </div>
        }
      </div>
      <comment-actions [actionsItem]="{}" [itemType]="resultType.Post"></comment-actions>
    </div>

    <!-- SHOW COMMENTS -->
    <div class="comments">
      @if (showComments) {
      @for (comment of myPost.comments; track comment) {
      <div class="comment">
        <post-comment [comment]="comment"></post-comment>
      </div>
      }
      }
    </div>
    <!-- END SHOW COMMENTS -->

    <!-- Add comment on user's post -->
    <div class="comment-section" [ngClass]="{'comment-section-sm': isSmallViewPort}">
      <button mat-icon-button [attr.aria-label]="ariaLabelAddComment" type="button" #tooltip="matTooltip"
        matTooltipPosition="above" [matTooltip]="toolTipLabelAddComment" (click)="showAddCommentForm()">
        @if (showAddComment) {
        <mat-icon class="material-symbols-outlined">chat_error</mat-icon>
        } @else {
        <mat-icon class="material-symbols-outlined">add_comment</mat-icon>
        }
      </button>

      <!-- ADD COMMENT -->
      @if (showAddComment) {
      <add-comment [postId]="myPost.postId!" [firstName]="profileService.userProfile().firstName!"
        [lastName]="profileService.userProfile().lastName!"></add-comment>
      }
      <!-- /END ADD COMMENT -->
    </div>
  </kendo-card-body>
</kendo-card>
} @placeholder {
<p class="error-placeholder">
  <mat-icon class="material-symbols-outlined error-placeholder-icon">
    error
  </mat-icon>
  An error occurred while loading the post.
</p>
}